var App = function() {


    function scroll()
    {
        $( '.page-scroll' ).on( 'click', function()
        {
            var anchor = $( this );

            $( 'html, body' ).stop().animate({
                scrollTop: $( anchor.attr( 'href' ) ).offset().top - $( '#header-bar-final').height()
            }, 1000 );

            return false;
        } );
    }

    function someFunction()
    {
        $('.selectpicker').selectpicker( { dropupAuto: false, mobile: true, title: 'Please Select..', width: '100%' } );

        $('.js-request-quote-form').submit( function( event )
        {
            //
            var form = $(this);
            var buttonText = form.find('button[type="submit"]').html();

            // Disable the submit button
            form.find('button[type="submit"]').prop('disabled', true).html( 'Processing..' );

            // Submit the form
            $.post( form.attr('action'), form.serialize(), function( data )
            {
                // Remove current error messages
                form.find('.form-group').removeClass('form-error').find('.label-desc').html('');

                if ( data.result == 'error' )
                {
                    // Enable the submit button
                    form.find('button[type="submit"]').prop('disabled', false).html( buttonText );

                    $.each( data.errors , function( key, value ) {
                        form.find('label[for="'+ key +'"]').closest('.form-group').addClass('form-error').find('.label-desc').html( '(' + value + ')' );
                    });

                    $( '#scroll-cta-button' ).click();
                }
                else
                {
                    // Track Google Adwords conversion
                    goog_report_conversion();

                    // Hide form and show success message
                    $('#header-feature-form-content').html( data.message );
                }
            }, 'json' );

            event.preventDefault();
        } );

        $('.js-request-quote-form').on( 'focus', '.selectpicker, input, textarea, select', function ()
        {
            $(this).closest('.form-group').removeClass('form-error').find('.label-desc').html('');
        } );
    }

    return {
        init: function() {
            scroll();
            someFunction();
        }
    }

}();



$(document).ready( function() {

    $( '#scroll-cta-button' ).on( 'click', function()
    {
        var scrollPos = $('#mobile').is(':visible') ? $( '#header-feature-form' ).offset().top - $( '#header-bar-final').height() : 0;

        $( 'html, body' ).stop().animate( { scrollTop: scrollPos }, 1000 );

        return false;
    } );

    if( !navigator.userAgent.match(/iPhone|iPad|iPod/i) ) {
        // Fixed header
        $(window).scroll(function () {
            if ($(window).scrollTop() > $('#header-top').outerHeight())
                $('#header-bar').addClass('fixed');
            else
                $('#header-bar').removeClass('fixed');
        });
    }

    $(window).scroll(function ()
    {
        if ( $(window).scrollTop() > $('#header').height() - 10 )
        {
            $('#header-bar-final li.cta').addClass('cta-visible');
        }
        else
        {
            $('#header-bar-final li.cta').removeClass('cta-visible');
        }
    });

    /*$( '#js-btn-learn' ).click( function( event ) {
        event.preventDefault();
        $(window).stop(true).scrollTo($('.section-lead').offset().top - $('#header-bar').height(), {
            duration: 1000,
            interrupt: true
        });
    } );*/

    /*$('.toggleNavigation').click( function() {
        $("#header-navigation").toggleClass("showNavigation");
    } );*/

    /*if( !navigator.userAgent.match(/iPhone|iPad|iPod/i) ) {
        $('#js--request-quote-modal').on('shown.bs.modal', function () {
            $("#js--request-quote-modal input[name=name]").focus();
        });
    }*/

    $('.m-testimonials .js-slick-content').slick( { 'draggable': false, 'prevArrow': $('.m-testimonials .js-slick-buttons .js-prev'), 'nextArrow': $('.m-testimonials .js-slick-buttons .js-next') } );

    // ------------------------------

    $('#js--request-quote-modal').on('shown.bs.modal', function (e) {
        ga('send', 'event', 'Get Quote', 'Button Clicked');
    });

    $('#js--request-quote-form').submit( function( event ) {

        // Disable the submit button
        $('#js--request-quote-submit').prop( 'disabled', true ).html( 'Please Wait..');

        // Submit the form
        $.post( $(this).attr( 'action' ), $(this).serialize(), function( data ) {

            // Remove current error messages
            $( '#js--request-quote-form').find('.form-group').removeClass('has-error').find('.input-error').hide();

            if ( data.result == 'error' )
            {
                // Enable the submit button
                $('#js--request-quote-submit').prop( 'disabled', false ).html( 'Get Your Free Quote' );

                $.each( data.errors , function( key, value ) {

                    // Show an error message for each form field that has an error
                    $('#js--request-quote-form').find( '[name="'+ key +'"]' ).closest('.form-group').addClass('has-error');//.find('.label-desc').html( value );
                    $('#js--request-quote-form label[for="'+ key +'"]').addClass('has-error').find('.label-desc').html( value );
                    //.find( '[name="'+ key +'"]' ).attr( 'placeholder', value );

                });

                // Scroll to the first error
                $("#js--request-quote-modal").scrollTo( $('.has-error:first') );
            }
            else
            {
                // Track Google Adwords conversion
                goog_report_conversion();

                // Hide form and show success message
                $('#js--request-quote-modal .modal-form').html( data.message );
            }

        }, 'json' );

        event.preventDefault();
    });

    /*$('#js--request-quote-form input, #js--request-quote-form textarea, #js--request-quote-form select').focus( function() {
        $(this).closest('.form-group').removeClass('has-error').find('.label-desc').html('');
        //$(this).attr( 'placeholder', $(this).attr('title') );
    });*/

    $('#js--request-quote-form').on( 'focus', 'input, textarea, select', function () {

        $(this).closest('.form-group').removeClass('has-error');
        $('#js--request-quote-form label[for="'+ $(this).attr('name') +'"]').removeClass('has-error').find('.label-desc').html('');

        if ( $(this).attr('name') == 'car_make' || $(this).attr('name') == 'car_model' || $(this).attr('name') == 'car_badge' )
        {
            //$('#js--request-quote-form').find('.js-car-make, .js-car-model, .js-car-badge').closest('.form-group').removeClass('has-error').find('.label-desc').html('');
        }

    });

    $('#phone_call_time li a').click( function (e) {
        var $div = $(this).parent().parent().parent();
        var $btn = $div.find('button');

        $(this).closest('ul').children('li').removeClass('active');
        $(this).closest('li').addClass('active');

        $btn.html( '<strong>Call Me:</strong> ' + $(this).text() + ' <span class="caret"></span>');
        $div.removeClass('open');

        $('#js--phone_call_time_val').val( $(this).text() );

        e.preventDefault();
        return false;
    });

    // ------------------------------

    /*
     $(window).scroll( function() {
     if ( sectionClicked ) return;

     var scrollPos = $(this).scrollTop();

     pageSections.each( function() {
     var sectionId = $(this).attr('id');
     var sectionTop = $(this).offset().top - navHeight - navHeight;
     var sectionBottom = sectionTop + $(this).outerHeight();

     if ( sectionCurrent != sectionId && scrollPos >= sectionTop && scrollPos <= sectionBottom ) {
     $('#header-navigation a[href="#'+ sectionId +'"]').trigger( 'click', [ true ] );
     }
     } );
     } );*/


    /*$(".mainNav li:not(.active)").click( function() {
     $(this).addClass("active");
     $(this).siblings().removeClass("active");
     });*/


    // ----------------------------------

    // iOS check...ugly but necessary
    /*if( navigator.userAgent.match(/iPhone|iPad|iPod/i) ) {
     $('.modal').on('show.bs.modal', function() {
     // Position modal absolute and bump it down to the scrollPosition
     $(this)
     .css({
     position: 'absolute',
     marginTop: $(window).scrollTop() + 'px',
     bottom: 'auto'
     });
     // Position backdrop absolute and make it span the entire page
     //
     // Also dirty, but we need to tap into the backdrop after Boostrap
     // positions it but before transitions finish.
     //
     setTimeout( function() {
     $('.modal-backdrop').css({
     position: 'absolute',
     top: 0,
     left: 0,
     width: '100%',
     height: Math.max(
     document.body.scrollHeight, document.documentElement.scrollHeight,
     document.body.offsetHeight, document.documentElement.offsetHeight,
     document.body.clientHeight, document.documentElement.clientHeight
     ) + 'px'
     });
     }, 0);
     });
     }*/

    //var header_img = "{{ $car['image'] }}";
    //$('.heroArea').css( 'background-image', 'url("' + header_img + '")' );

    ///////////////////////////////////

    ///////////////////////////////////

    var locationApi = new Bloodhound({
        datumTokenizer: Bloodhound.tokenizers.obj.whitespace('value'),
        queryTokenizer: Bloodhound.tokenizers.whitespace,
        remote: {
            url: site_url + '/locationapi/%QUERY.json',
            wildcard: '%QUERY'
        }
    });

    $('#js--request-quote-form input[name="location"]').typeahead({
        delay: 100,
        hint: true,
        highlight: true,
        minLength: 3
    },{
        display: 'value',
        source: locationApi
    });


    $('.js-car-make').on( 'change', function ()
    {
        $('.js-car-make').val( $(this).val() );

        var url = site_url + '/cars/' +  $(this).val();

        $.get( url, function ( data )
        {
            $('select.js-car-model').empty().append(data).trigger('change').selectpicker('refresh');
            //$('.selectpicker').selectpicker('refresh');
        } );

    } );

    //$('.js-car-model-container').on( 'change', '.js-car-model', function () {

    $('.js-car-model').on( 'change', function () {

        $('.js-car-model').val( $(this).val() );
        setCar();

        var url = site_url + '/cars/' +  $('select.js-car-make').val() + '/' + ( ( $(this).val() != null ) ? $(this).val() : 'no-model' );

        $.get( url, function ( data )
        {
            //$('.js-car-badge-container').html( data );
            $("select.js-car-badge").empty().append(data).selectpicker('refresh');
            //$('.selectpicker').selectpicker('refresh');
        } );

    });

    //$('.js-car-badge-container').on( 'change', '.js-car-badge', function () {
    $('.js-car-badge').on( 'change', function () {

        $(".js-car-badge").val( $(this).val() );
        $('.selectpicker').selectpicker('refresh');

    });


    function setCar()
    {
        var car_make = $('.js-car-make:first').find('option:selected').text();
        var car_model = $('.js-car-model:first').find('option:selected').text();
        var car_text = ( car_make == '' ? 'car' : car_make + ( car_model == '' ? '' : ' ' + car_model ) );

        $('.js-car-name').html( car_text );
        $('.js-car-name-year').html( ( car_make == '' ? 'car' : '2015 ' + car_text ) );
    }

    if ( car.make != $('.js-car-make').val() ) {
        $('.js-car-make').val( car.make );
    }

    if ( car.model != $('.js-car-model').val() ) {
        $('.js-car-model').val( car.model );
    }

    if ( car.series != $('.js-car-badge').val() ) {
        $('.js-car-badge').val( car.series );
    }

    $('#js--contact-form').submit( function( event ) {

        // Disable the submit button
        $('#js--contact-form button').prop( 'disabled', true ).html( 'Please Wait..');

        // Submit the form
        $.post( $(this).attr( 'action' ), $(this).serialize(), function( data ) {

            // Remove current error messages
            $( '#js--contact-form').find('.form-group').removeClass('has-error').find('.input-error').hide();

            if ( data.result == 'error' )
            {
                // Enable the submit button
                $('#js--contact-form button').prop( 'disabled', false ).html( 'Send Message' );

                $.each( data.errors , function( key, value ) {

                    $( '#js--contact-form' ).find( '[name="'+ key +'"]' ).closest('.form-group').addClass('has-error').find('.label-desc').html( value );
                });
            }
            else
            {
                alert( 'success' );
            }

        }, 'json' );

        event.preventDefault();
    });

    $('#js--contact-form input, #js--contact-form textarea').focus( function() {
        $(this).closest('.form-group').removeClass('has-error').find('.label-desc').html('');
    });

    // Match to Bootstraps data-toggle for the modal
    // and attach an onclick event handler
    $('a[data-load="ajax"]').on('click', function(e) {

        // From the clicked element, get the data-target arrtibute
        // which BS3 uses to determine the target modal
        var target_modal = $(e.currentTarget).data('target');
        // also get the remote content's URL
        var remote_content = e.currentTarget.href;

        // Find the target modal in the DOM
        var modal = $(target_modal);
        //
        var modalTitle = $(target_modal + ' .modal-title');
        // Find the modal's <div class="modal-body"> so we can populate it
        var modalBody = $(target_modal + ' .modal-body');

        // Capture BS3's show.bs.modal which is fires
        // immediately when, you guessed it, the show instance method
        // for the modal is called
        modal.off('show.bs.modal');
        modal.on('show.bs.modal', function () {
            //
            modalTitle.html( $(e.currentTarget).text() )
            // use your remote content URL to load the modal body
            modalBody.load(remote_content);
        }).modal();
        // and show the modal

        // Now return a false (negating the link action) to prevent Bootstrap's JS 3.1.1
        // from throwing a 'preventDefault' error due to us overriding the anchor usage.
        return false;
    });

    /*
     $(".faqs li strong").click(function(){
     $(this).parent().toggleClass("active").find(".answer").slideToggle('fast');
     });*/

    /*
     var divs = $('.testimonial');

     function fade()
     {
     var current = $('.testimonial-current');
     var currentIndex = divs.index(current),
     nextIndex = currentIndex + 1;

     if (nextIndex >= divs.length) {
     nextIndex = 0;
     }

     var next = divs.eq(nextIndex);

     next.stop().fadeIn(2000, function() {
     $(this).addClass('testimonial-current');
     });

     current.stop().fadeOut(2000, function() {
     $(this).removeClass('testimonial-current');
     setTimeout(fade, 5000);
     });
     }

     fade();
     */


    // -----------------------------------------------------------------------

    /*var navHeight = $('#header-main').outerHeight();
    var pageSections = $('section').filter('[id]');
    var sectionClicked = false;
    var sectionCurrent = null;*/

    //
    /*$('#header-navigation a[href^="#"]').click( function( event ) {
        event.preventDefault();
        $('#header-navigation a[href^="#"]').removeClass('active');
        $(this).addClass('active');

        //$(this).parent().addClass('active');
        //$(window).stop( true ).scrollTo( $( '.' + $(this).attr('href').substring(1) ).offset().top - $( '#header-main' ).height(), { duration: 1000, interrupt: true } );
    } );*/

    /*$('#header-navigation a[href^="#"]').click( function( event, preventScroll ) {
     event.preventDefault();
     sectionCurrent = $(this).attr('href');
     //$(this).parent().siblings().removeClass('active');
     //$(this).parent().addClass('active');
     if ( !preventScroll ) { sectionClicked = true; $(window).stop(true).scrollTo( $(this.hash).offset().top - $('#header-main').height(), { duration: 1000, interrupt: true, onAfter: function() { sectionClicked = false }, fail: function() { sectionClicked = false } } ); }
     } );*/

});